
@media (max-width: 767px) {
  .player-editor-container {
    flex-direction: column-reverse;
    width: 100%;
  }

  .transcriptions-container {
    overflow-y: scroll;
    /* height: 30%; */
  }
  .subtitle-text {
    font-size: 0.5em;
  }
  .new-transcription:last-child {
    margin-bottom: 2em;
  }
}
