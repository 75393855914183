.current-timestamp-btn {
  font-size: 0.5em;
  color: #006ba6;
  margin-right: 0.3em;
}

.current-timestamp-container {
  display: flex;
  cursor: pointer;
  margin-top: 0.2em;
  text-decoration: underline;
  align-items: center;
}
