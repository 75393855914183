.navigation-control {
  width: 1.2em;
  cursor: pointer;
  fill: #006ba6;
  display: flex;
  align-items: center;
}

.navigation-control-btn {
  background-color: #fff;
  outline: none;
  border: 0;
}
