.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 1000ms;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.modal-overlay__visible {
  visibility: visible;
  opacity: 1;
  -webkit-animation: fadein 0.4s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.4s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.4s;
  /* Internet Explorer */
  -o-animation: fadein 0.4s;
  /* Opera < 12.1 */
  animation: fadein 0.4s;
}

.modal-content {
  background-color: #ffffff;
  opacity: 1;
  border-radius: 1.5em;
  min-width: 10em;
  padding: 1em;
  min-height: 5em;
}

.modal-footer {
  display: flex;
  border-top: 1px solid #ebf7ff;
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
}

.modal-header {
  display: flex;
  border-bottom: 1px solid #ebf7ff;
  display: flex;
}
