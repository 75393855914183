.transcription-input {
  margin-left: 1em;
  text-align: center;
  border: 0;
  outline: none;
  resize: none;
  white-space: pre-line;
  display: flex;
  line-height: 1.5em;
  padding-top: 1.2em;
  width: 100%;
  font-size: .9em;
}


@media (max-width: 767px) {
  .transcription-input {
    font-size: .8em;
  }
}

.transcription-input-cpl {
  display: flex;
  flex-direction: column;
  background-color: rgb(208, 213, 221);
  border-radius: 5%;
  padding: 0.3em;
  align-items: center;
}

.transcription-input-cpl-body {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.transcription-input-container {
  display: flex;
  padding: 0 1em 0 1em;
  width: 100%;
}

.transcription-input-cpl-header {
  margin-bottom: .2em;
}

.transcription-input-cpl-val {
  font-size: .7em;
  line-height: 1.5em;
}
