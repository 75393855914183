.plus-minus-icon {
  width: 0.5em;
  fill: #006ba6;;
}

.plus-minus-icon__large {
  width: 1em;
  fill: #006ba6;
}

.plus-minus-icon__xlarge {
  width: 0.5em;
  fill: #006ba6;
}

.plus-icon-container {
  height: 0.6em;
  width: 0.6em;
  border-radius: 50%;
  padding: 0.15em;
  border: 1px solid #006ba6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus-icon-container__large {
  height: 0.9em;
  width: 0.9em;
}

.plus-icon-container__xlarge {
  height: 2.4em;
  width: 2.4em;
}


