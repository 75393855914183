.player-editor-container {
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
}

/*  */
.player-container {
  width: 100%;
  position: relative;
  /* height: 26.8em; */
  border: 1px solid rgb(208, 213, 221);
  padding: 1em;
  padding-bottom: 1em;
  border-radius: 0.5em;
}

.editor-container {
  width: 45%;
}

.subtitle {
  position: absolute;
  bottom: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.subtitle-text {
  background-color: black;
  color: #fff;
  white-space: pre-line;
  text-align: center;
  padding: 2px;
}

.player-control-interval {
  display: flex;
}

.transcriptions-container {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.player-controls-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
}

.transcription-container {
  display: flex;
  border: 1px solid rgb(208, 213, 221);
  padding: 1em;
  /* border: 1px 0 1px 0 black solid; */
}

.transcription-container:last-child {
  margin-bottom: 0;
}

.button {
  margin: 1em;
  cursor: pointer;
}

.video-player {
  position: relative;
  cursor: pointer;
}

.video-player-subtitle {
  position: absolute;
  bottom: -8em;
  width: 100%;
}

.add-transcription {
  display: flex;
  color: #006ba6;
  text-decoration: underline;
  cursor: pointer;
  align-items: center;
  font-size: 0.8em;
  margin-bottom: 1em;
  margin-top: 1em;
}

.add-transcription-text {
  margin: 0;
  margin-right: 0.3em;
}

.new-transcription {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.new-transcription:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .player-editor-container {
    flex-direction: column-reverse;
    width: 100%;
  }

  .transcriptions-container {
    overflow-y: scroll;
    /* height: 30%; */
  }
  .subtitle-text {
    font-size: 0.5em;
  }
  .new-transcription:last-child {
    margin-bottom: 2em;
  }
}
