.time-frame-input {
  outline: none;
  width: 7em;
  border: 0;
  border-radius: .2em;
  padding: .2em;
  font-size: 12px;
  border: 0.1px solid rgb(208, 213, 221);
}

.timeframe-right {
  display: flex;
  flex-direction: column;
}

.timeframe-container {
  display: flex;
  align-items: center;
}

.time-frame-input:first-child {
  margin-bottom: 1em;
}


.time-frame-input:hover {
  background-color: rgb(208, 213, 221);
}

.time-frame-input__invalid {
  background-color: #f78d8d;
}
.time-frame-input__invalid:hover {
  background-color: #f78d8d;
}